


import axios from 'axios';
const useLoadApi = () => {
   
  
    
 
    


    const loadMarcas = async() => {
        
        try {
            const { data } = await axios.get('/json/marcas.json', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
            });
        
    
        
            return data;
        } catch (error) {
            console.error('Error al obtener los datos del archivo JSON', error);
        }
    }

    const loadAreas =  async() => {
        try {
            const { data } = await axios.get('/json/areas.json', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
            });
        
        
            
            return data;
        } catch (error) {
            console.error('Error al obtener los datos del archivo JSON', error);
        }
    }

    const loadUsuarios = async() => {
        try {
            const { data } = await axios.get('/json/usuarios.json', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
            });
        
        
        
            return data;
        } catch (error) {
            console.error('Error al obtener los datos del archivo JSON', error);
        }
    }

    const loadEstados = async() => {
        try {
            const { data } = await axios.get('/json/estados.json', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
            });
        
        
        
            return data;
        } catch (error) {
            console.error('Error al obtener los datos del archivo JSON', error);
        }
    }

    const loadPrioridades = async() => {
        try {
            const { data } = await axios.get('/json/prioridades.json', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
            });
        
        
        
            return data;
        } catch (error) {
            console.error('Error al obtener los datos del archivo JSON', error);
        }
    }

    const loadClientes = async(state2) => {
        try {
          const { data } = await axios.get('/json/clientes.json', {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          });
            data.forEach((cliente) => {
         
                cliente.isOpen = false;
                cliente.proyectos.forEach((proyecto) => {
                    cliente.isActive = false;
                    const clienteName = formatted(state2, cliente.ClientId);

                    const ProyectName = proyecto.name.toLowerCase().replace(/[\s-]+/g, '-');
                    proyecto.link = `/clientes/${clienteName}/${ProyectName}`;
                    if (process.client) {
                        const storedValue = localStorage.getItem(`isOpenTask_${proyecto.name}`);
                        proyecto.isOpenTask = storedValue !== null ? JSON.parse(storedValue) : true;
                    }
        
                });
            });
          
            return data;
        } catch (error) {
          console.error('Error al obtener los datos del archivo JSON', error);
        }
    }

    const loadPieces = async() => {
        try {
            const { data } = await axios.get('/json/pieces.json', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
            });
        
        
        
            return data;
        } catch (error) {
            console.error('Error al obtener los datos del archivo JSON', error);
        }
    }
    
    const loadGetTeams = async() => {
        try {
            const { data } = await axios.get('/json/GetTeams.json', {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
        
        
        
            return data;
        } catch (error) {
            console.error('Error al obtener los datos del archivo JSON', error);
        }
    }

    const loadGetProjectsByClient = async(clienteName) => {
        try {
            const { data } = await axios.get('/json/GetProjectsByClient.json', {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
            
           
            data.Projects.forEach((element) => {
                const ProyectName = element.StatusName.toLowerCase().replace(/[\s-]+/g, '-');
                element.link = `${ProyectName}`;
            })

         
        
        
            return data.Projects;
        } catch (error) {
            console.error('Error al obtener los datos del archivo JSON', error);
        }
    } 

    const loadGetTasksAssignedToUser = async() => {
        try {
            const { data } = await axios.get('/json/GetTasksAssignedToUser.json', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
            });
        
        
        
            return data;
        } catch (error) {
            console.error('Error al obtener los datos del archivo JSON', error);
        }
    }

    const loadGetTasksByProjectPriority = async () => {
        try {
            const { data } = await axios.get('/json/GetTasksByProjectPriority.json', {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
    
            return data.Tasks
    
        } catch (error) {
            console.error('Error al obtener los datos del archivo JSON', error);
        }
    };

    const loadGetTasksByProjectStatus = async () => {
        try {
            const { data } = await axios.get('/json/GetTasksByProjectStatus.json', {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

          
    
            return data.Tasks
    
        } catch (error) {
            console.error('Error al obtener los datos del archivo JSON', error);
        }
    };
    

 

    const formatted = (state, id) => {
        if(state && id !== undefined && id !== null) {
            const nombre = state.find(p => p.Id == id);
            if (nombre) {
                return nombre.StatusName
            } else {
                return `${nombre} No encontrado`;
            }
        }
      
    }

    const formattedId = (state, name) => {
        if(state && name !== undefined && name !== null) {
            const codigo  = state.find(p => p.StatusName == name);
            if (codigo) {
                return codigo.Id
            } else {
                return `${codigo} No encontrado`;
            }
        }
      
    }

    const formattedLink = (state, name) => {
       
        const nombre = state.find(u => { u.link == name})
        
        if (nombre) {
            return nombre.Id;
        } else {
            return `${nombre} No encontrado`;
        }
    }
   

    return {
        loadMarcas,
        loadAreas,
        loadUsuarios,
        loadEstados,
        loadPrioridades,
        loadClientes,
        loadPieces,
        loadGetTeams,
        loadGetTasksAssignedToUser,
        loadGetProjectsByClient,
        loadGetTasksByProjectStatus,
        loadGetTasksByProjectPriority,

        formatted,
        formattedId,
        formattedLink
    
        
    }


}

export default useLoadApi