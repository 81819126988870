import { defineStore } from 'pinia'
import useLoadApi from '~/composables/useLoadApi';



export const useUser =  defineStore('user', {
    state: () => ({
        clientes: null,
        marcas:null,
        areas: null,
        usuarios: null,
        estados: null,
        prioridades:null,
        pieces:null,
        isLoading: false,
        userRol: "supervisor",
        UserData:null

    }),
})

